/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper, gsap) => {

        let sectorSwiper = new Swiper('.testi .swiper', {
            slidesPerView: 1,
            spaceBetween: 80,
            navigation: {
                nextEl: '.testi .testi-nav-next',
                prevEl: '.testi .testi-nav-prev',
            },
            breakpoints: {
                1024: {
                    slidesPerView: 2
                }
            },
            on: {
                init: function() {
                    updateProgressBar(this.progress)
                },
                slideChange: function() {
                    updateProgressBar(this.progress)
                }
            }
        })

        function updateProgressBar(progress) {
            gsap.to('.testi-progress .testi-progress-bar', {
                width: progress * 100 +'%',
                duration: .4
            })
        }

    }
}
