/*** IMPORTS FROM imports-loader ***/
var define = false;

import { FOCUSABLE_ELEMENTS, lockScroll, unlockScroll, delegateEventListener } from '../utils/global'

const DEFAULTS = {
    stackable: false
}

export default class Overlay {
    constructor(overlay, options = {}) {
        this.bindMethods()

        this.id = overlay.id
        this.overlay = overlay
        this.options = {
            ...DEFAULTS,
            ...options
        }

        if (!overlay.getAttribute('role')) overlay.setAttribute('role', 'dialog')
        if (!overlay.getAttribute('aria-modal')) overlay.setAttribute('aria-modal', true)

        delegateEventListener(document, 'click', `[data-open][aria-controls="${this.id}"]`, this.open)
        delegateEventListener(document, 'click', `[data-close][aria-controls="${this.id}"], #${this.id} [data-close]`, this.close)

        if (!this.options.stackable) {
            addEventListener('overlayopening', (e) => {
                if (e.detail.id !== this.id && this.isOpened) this.close()
            })
        }
    }

    bindMethods() {
        this.onKeyPress = this.onKeyPress.bind(this)
        this.trapFocus = this.trapFocus.bind(this)
        this.open = this.open.bind(this)
        this.opened = this.opened.bind(this)
        this.close = this.close.bind(this)
        this.closed = this.closed.bind(this)
    }

    get isOpened() { return this.overlay.dataset.opened === 'true' }

    // Get focusable elements
    get focusableElements() { return this.overlay.querySelectorAll(FOCUSABLE_ELEMENTS) }

    get firstFocusableElement() {
        return this.focusableElements[0]
    }

    get lastFocusableElement() {
        const focusableElements = this.focusableElements

        return focusableElements[focusableElements.length - 1]
    }

    // Dispatch event with id attached
    emit(name, data = {}) {
        dispatchEvent(new CustomEvent(`overlay${name}`, {
            detail: {
                id: this.options.id,
                ...data
            }
        }))

        this.overlay.dispatchEvent(new CustomEvent(name, {
            detail: data
        }))
    }

    // Handle key presses for keyboard accessibility
    onKeyPress(e) {
        // Close with escape key
        if (e.key == 'Escape') return this.close()

        // Handle tab
        if (e.key !== 'Tab') return

        // Go from first to last
        if (e.shiftKey && document.activeElement === this.firstFocusableElement) {
            e.preventDefault()
            this.lastFocusableElement.focus()
            return
        }

        // Go from last to first
        if (!e.shiftKey && document.activeElement === this.lastFocusableElement) {
            e.preventDefault()
            this.firstFocusableElement.focus()
            return
        }

        this.overlay.removeAttribute('tabindex')
    }

    // Trap keyboard focus inside overlay
    trapFocus() {
        // Store last focused element
        this.returnFocusTo = document.activeElement

        // Add keypress events
        document.addEventListener('keydown', this.onKeyPress)

        // Focus overlay
        this.overlay.tabIndex = 0
        this.overlay.focus()
    }

    // Release focus from overlay
    releaseFocus() {
        // Remove keypress events
        document.removeEventListener('keydown', this.onKeyPress)
        this.overlay.removeAttribute('tabindex')

        // Return focus to last focused element
        if (!this.returnFocusTo) return

        this.returnFocusTo.focus()
        this.returnFocusTo = null
    }

    // Open overlay
    open(e) {
        if (e && e.preventDefault) e.preventDefault()

        this.emit('opening')

        this.overlay.style.display = 'flex'

        this.trapFocus()

        // Request scroll lock
        lockScroll()

        this.overlay.removeEventListener('transitionend', this.closed)
        this.overlay.addEventListener('transitionend', this.opened, { once: true })
        setTimeout(() => this.overlay.dataset.opened = true, 1)
    }

    close(e) {
        if (e && e.preventDefault) e.preventDefault()

        this.emit('closing')

        this.releaseFocus()

        // Request scroll unlock
        unlockScroll()

        this.overlay.dataset.opened = false
        this.overlay.removeEventListener('transitionend', this.opened)
        this.overlay.addEventListener('transitionend', this.closed, { once: true })
    }

    opened() {
        this.emit('opened')
    }

    closed() {
        this.overlay.style.display = 'none'

        this.emit('closed')
    }
}
