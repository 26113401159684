/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Ajax) => {

        // events filter
        let eventsXhr = null

        $('[data-type]').on('click', function (e) {
            e.preventDefault()

            let $this = $(this),
                isActive = $this.hasClass('--active')

            $('[data-type].--active').toggleClass('--active', false)

            if (!isActive) {
                $(this).toggleClass('--active', true)
            }

            filterEvents()
        })

        $('.block-hero-yr').on('click', '[data-year]', function () {
            let year = $(this).data('year')

            $('.block-hero__years-select-current').html(year)
            $('.block-hero__years-select-current').data('year', year)

            filterEvents()
        })

        $('.events-months').on('click', '[data-month]', function () {
            let $this = $(this),
                isActive = $this.hasClass('--active')

            $('[data-month].--active').toggleClass('--active', false)

            if (!isActive) {
                $(this).toggleClass('--active', true)
            }

            filterEvents()
        })

        function filterEvents() {
            if (eventsXhr) eventsXhr.abort()

            let type = $('[data-type].--active').data('type')

            let year = $('.block-hero__years-select-current').data('year')

            let month = $('.events-months-item.--active').data('month')

            eventsXhr = new Ajax({
                url: '/ajax/events',
                data: {
                    type: type ? type : '',
                    year: year ? year : '',
                    month: month ? month < 10 ? '0' + month : month : ''
                },
                onLoadStart: () => {
                    $('.events-list').toggleClass('spinner', true)
                    $('.events-list').html('')
                },
                onSuccess: (response) => {
                    $('.events-list').toggleClass('spinner', false)
                    $('.events-list').html(response)

                    document.querySelector('.events-list').querySelectorAll('[data-src], [data-bg]').forEach((item) => app.lazyLoader.observe(item))
                }
            })
        }






        // posts filter
        let postsXhr = null

        $('[data-cat]').on('click', function (e) {
            e.preventDefault()

            let $this = $(this),
                isActive = $this.hasClass('--active')

            $('[data-cat].--active').toggleClass('--active', false)

            if (!isActive) {
                $(this).toggleClass('--active', true)
            }

            filterPosts(0)
        })

        $('.posts').on('click', '.pagination-item', function (e) {
            e.preventDefault()

            filterPosts($(this).data('index'))
        })

        $('.posts').on('click', '.pagination-prev', function (e) {
            e.preventDefault()

            let $prev = $('.pagination-item.--active').prev('.pagination-item'),
                index = $prev.length > 0 ? $prev.data('index') : 0

            filterPosts(index)
        })

        $('.posts').on('click', '.pagination-next', function (e) {
            e.preventDefault()

            let $next = $('.pagination-item.--active').next('.pagination-item'),
                index = $next.length > 0 ? $next.data('index') : $('.pagination-item').last().data('index')

            filterPosts(index)
        })

        function filterPosts(pagination) {
            if (postsXhr) postsXhr.abort()

            let cat = $('[data-cat].--active').data('cat')

            postsXhr = new Ajax({
                url: '/ajax/posts',
                data: {
                    cat: cat ? cat : '',
                    pagination: pagination ? pagination : 0
                },
                onLoadStart: () => {
                    $('.posts-container').toggleClass('spinner', true)
                    $('.posts-container').html('')
                },
                onSuccess: (response) => {
                    $('.posts-container').toggleClass('spinner', false)
                    $('.posts-container').html(response)

                    document.querySelector('.posts-container').querySelectorAll('[data-src], [data-bg]').forEach((item) => app.lazyLoader.observe(item))
                }
            })
        }
    }
}
