/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Plyr) => {

		/*	
		|
		| Plyr
		|-----------------
		*/
		if (jQuery('.custom-plyr-video')[0]) {
			const $customPlyrVideo = jQuery('.custom-plyr-video');
			$.each($customPlyrVideo, function () {
				const $video = $(this);
				const $videoPoster = $video.find('.item-poster');
				const $videoIcon = $video.find('.item-play-icon');
				const videoId = $video.data('id');
				const type = $video.data('type');
				let player = new Plyr(`#${videoId}`);
				$(`#${videoId}`).addClass('custom-plyr-video');

				player.on("play", (event) => {
					let delayInMilliseconds = 500;
					setTimeout(function () {
						jQuery('.item-poster').css('opacity', '0');
					}, delayInMilliseconds);
				});
			});
		}

	}
}

